
import logoTransparent from '../../assets/img/logo_transparent.png'

function Header() {
  return (
    <header className="App-header">
        <img src={logoTransparent} className="App-logo" alt="logo" />
      </header>
  )
}

export default Header